import { useParams } from "react-router-dom";
import { fetchTaxbill } from "./taxApi";
import { useQuery } from 'react-query';
import { TaxBillForm } from "./TaxbillForm";

export const TaxBillEdit = ()=>{
    const { salesId, id } = useParams();
    
    // 가져오기
    const {data:taxbill, isLoading, error, isError } = useQuery({
        queryKey:['taxbill', id], 
        queryFn:fetchTaxbill
    });

    if(isLoading) return null;
    if( isError ) return error;

    console.log(taxbill);
    return (
        <div className="container m-5">
            <TaxBillForm taxbill={taxbill.data} salesId={salesId} />
        </div>
    );
}
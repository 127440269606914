export const LIBRARY_TYPES = {
    'PUBLIC':'공공','COLLEGE':'대학', 'PRIVATE':'개인', 'ETC':'기타'
};

export const LIBRARY_SERVICE_TYPES = {
    'EBOOK':'전자책', 'THECHEAK':'더책', 'DVD':'DVD', 'POOQ':'웨이브', 'KOLAS':'코라스', 'ASP':'ASP', 'ETC':'기타'
};

export const TAXBILL_TYPES = {
    'BUY':'매입','SALE':'매출'
};
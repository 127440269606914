import ApiClient from '../../utils/api/ApiClient';

export function signin(requestData){
    const [promise]= ApiClient({
        url:'/api/user/signin',
        method:'POST',
        data:requestData,
    });
    return promise;
}//end



export function userJoin(userData)
{
    return async function(dispatch){
        const [promise] = ApiClient({
            url: '/api/user/join',
            data:userData
        });
        return promise
    };
}// userJoin

import { Route, Routes } from 'react-router-dom';
import MainPage from "./MainPage";
import Signin from './user/Signin';
import ReadingVideoList from './reading/video/ReadingVideoList';
import LibraryList from './library/LibraryList';
import LibraryView from './library/LibraryView';
import SalesList from './finance/sales/SalesList';
import SalesView from './finance/sales/SalesView';
import { TaxbillList } from './finance/taxbill/TaxbillList';
import { TaxBillEdit } from './finance/taxbill/TaxbillEdit';
import { TaxBillCreate } from './finance/taxbill/TaxBillCreate';
import { SalesCreate } from './finance/sales/SalesCreate';
import { SalesEdit } from './finance/sales/SalesEdit';
import { Header } from './Header';
import useUserTokenChecker from 'utils/hooks/useUserTokenChecker';


function App() {
  useUserTokenChecker();
  return (
    <div className="App">
      <Header />
      <div className="body">
        
        <Routes>
          <Route path="/" exact={true} element={<MainPage />} />
          <Route path="/signin" element={<Signin />} />

          <Route path="/reading/video" element={<ReadingVideoList />} />

          <Route path="/library" exact element={<LibraryList />} />
          <Route path="/library/:libraryId" element={<LibraryView />} />

          <Route path="/sales" element={<SalesList />} />
          <Route path="/sales/create" element={<SalesCreate />} />
          <Route path="/sales/edit/:id" element={<SalesEdit />} />
          <Route path="/sales/view/:id" element={<SalesView />} />
          <Route path="/sales/taxbill/create/:salesId" element={<TaxBillCreate />} />
          <Route path="/sales/taxbill/edit/:salesId/:id" element={<TaxBillEdit />} />

          <Route path="/taxbill" element={<TaxbillList />} />
          <Route path="/taxbill/create" element={<TaxBillCreate />} />
          <Route path="/taxbill/edit/:id" element={<TaxBillEdit />} />
        </Routes>
      </div>
    </div>
  );
}
export default App;

import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from 'jwt-decode';

const initialState = {
    authenticated:false,  name:null, isAdmin:false
};
const slice = createSlice({
    name:"auth",
    initialState,
    reducers:{
        authenticated:(state, action)=>{
            return {...state, ...action.data, authenticated:true};
        },
        unauthenticated:()=>initialState,
    }
});

export const authAction = slice.actions;
export default slice.reducer;

export const authenticated = (authResponse) =>{
    return function(dispatch){
        const tokenData = jwt_decode(authResponse.token);

        sessionStorage.setItem('refreshToken', authResponse.refreshToken);        
        localStorage.setItem('user', authResponse.token);
        localStorage.setItem('expireAt', tokenData.exp);

        dispatch(authAction.authenticated(tokenData));
    };
}

export const unauthenticated = () =>{
    return function(dispatch){
        if( localStorage.getItem("user")===undefined ) return;
        localStorage.removeItem("user");
        dispatch(authAction.unauthenticated());        
    };
}


export const initialAuthWithToken = ()=>{
    return function(dispatch){
        const token = localStorage.getItem('user');
        if( token ===null ) return;
        const tokenData = jwt_decode(token);
        dispatch( authAction.authenticated(tokenData) );
    }
}

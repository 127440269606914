import { initialAuthWithToken } from 'app/reducers/auth';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";

export default function useUserTokenChecker(){
    const dispatch =  useDispatch();

    useEffect(() => {
        dispatch(initialAuthWithToken());
    }, [dispatch]);
};

const MainPage = ()=>{

    return (
        <>
        <div className="d-grid d-sm-flex justify-content-sm-center m-5">
            <a className="btn btn-success btn-lg" target="_blank" rel="noreferrer"  href="http://humac.seetrol.com">원격제어</a>
        </div>
        </>
    )
}

export default MainPage;
import ApiClient from "utils/api/ApiClient";

export const fetchSiteList = (param)=>{
    const [promise]= ApiClient({
        url:`/api/library/site/list/${param?.queryKey[1]}`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export function deleteSite(id){
    const [promise]= ApiClient({
        url:`/api/library/site/${id}/delete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export function storeSite(storeData){
    const [promise]= ApiClient({
        url:'/api/library/site/store',
        method:'POST',
        data:storeData,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
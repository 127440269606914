import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useQueryString from "../../../utils/hooks/useQueryString";
import Pagination from "../../component/Pagination";
import { VideoItem } from "./VideoItem";
import { fetchYoutubeList } from "./videoApi";
import { useQuery } from 'react-query';
const queryString = require('query-string');


const ReadingVideoList = ()=>{
    const navigate = useNavigate();
    const qsData = useQueryString();

    const {data: youtubeList, isSuccess, isLoading, error } = useQuery({
        queryKey:['youtubeList', qsData], 
        queryFn:fetchYoutubeList
    });

    const fetchList = (page = 0)=>{
        let tempSearchOption = {...qsData, page:page };
        navigate({ pathname: '/reading/video', search: "?" + queryString.stringify(tempSearchOption) });
    };
    
    const videoList = useMemo(() => {        
        if(isLoading) return <tr><td>...loading</td></tr>;
        if(error) return <tr><td> {error.message}</td></tr>;
        return youtubeList?.data?.content.map( (video)=>{
            return <VideoItem video={video}  key={video.id} />;
        });
      }, [isLoading, error, youtubeList]);

    return (
        <div className="readingVideoList">
            <div className="d-flex justify-content-center py-3">
                <ul className="nav nav-pills">
                    <li className="nav-item"><Link className={`nav-link ${qsData?.state==='WATTING'?'active':''}`} to="/reading/video?state=WATTING">대기</Link></li>
                    <li className="nav-item"><Link className={`nav-link ${qsData?.state==='ACTIVE'?'active':''}`} to="/reading/video?state=ACTIVE">활성</Link></li>
                    <li className="nav-item"><Link className={`nav-link ${qsData?.state==='REJECT'?'active':''}`} to="/reading/video?state=REJECT">등록거부</Link></li>
                    <li className="nav-item"><Link className={`nav-link ${qsData?.state==='DELETE_REQUEST'?'active':''}`} to="/reading/video?state=DELETE_REQUEST">삭제요청</Link></li>
                    <li className="nav-item"><Link className={`nav-link ${qsData?.state==='DELETE'?'active':''}`} to="/reading/video?state=DELETE">삭제</Link></li>
                </ul>
            </div>
            <div className="list m-2">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>기관</th>
                            <th>학교</th>
                            <th>아이디</th>
                            <th>이름</th>

                            <th>서명</th>
                            <th>제목</th>
                            <th>공개</th>
                            <th>상태</th>
                            <th>관리</th>
                            <th>신청일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {videoList}
                    </tbody>
                </table>
            </div>

            {isSuccess && <Pagination page={youtubeList.data?.number} totalPage={youtubeList.data?.totalPages} gotoPage={fetchList} />}
        </div>
    );
}

export default ReadingVideoList;
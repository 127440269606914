import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useQueryString from "../../utils/hooks/useQueryString";
import Pagination from "../component/Pagination";
import { LIBRARY_TYPES } from "../const";
import { fetchLibraryList } from "./libraryApi";
import LibrarySearch from "./LibrarySearch";
import { useQuery } from 'react-query';
const queryString = require('query-string');

const LibraryList = ()=>{
    const navigate = useNavigate();
    const qsData = useQueryString();

    const {data:libraryList, isSuccess, isLoading, error, isError } = useQuery({
        queryKey:['libraryList', qsData], 
        queryFn:fetchLibraryList
    });

    const fetchList = (page)=>{
        let tempSearchOption = {...qsData, page:page };
        navigate({ pathname: '/library', search: "?" + queryString.stringify(tempSearchOption) });
    }
    
    const libraryListElements = useMemo(() => {
        if(isLoading) return null;
        if( isError ) return error;

        return libraryList.data.list.map((lib, key)=>{
            return(
                <div className="library mt-1" key={key}>
                    <span>{lib.province}</span>
                    <span className={`type ${lib.type}`}>{LIBRARY_TYPES[lib.type]}</span>
                    <Link to={`/library/${lib.id}`}>
                        {lib.name}
                    </Link>
                </div>
            )
        })
    }, [isLoading, libraryList, error, isError]);

    return (
        <div className="container">
            <LibrarySearch />
            <div className="libraryList mb-5">
                {libraryListElements}
            </div>
            {isSuccess && <Pagination page={libraryList.data?.page} totalPage={libraryList.data?.totalPages} gotoPage={fetchList} /> }
        </div>
        
    );
}
export default LibraryList;
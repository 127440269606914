import ApiClient from "utils/api/ApiClient";

export const fetchTaxbillBySalesList = (params)=>{
    const [promise]= ApiClient({
        url:`/api/sales/${params?.queryKey[1]}/taxbills/${params?.queryKey[2]}`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export function fetchTaxbillList(params){
    const requestData = {page:0, keyword:null, ...params?.queryKey[1] };
    const [promise]= ApiClient({
        url:`/api/taxbill?page=${requestData.page}`,
        data:requestData,
        method:'post',
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export const fetchTaxbill = (params)=>{

    const [promise]= ApiClient({
        url:`/api/taxbill/${params?.queryKey[1]}`,
        authToken:localStorage.getItem('user')
    });

    return promise;
    //     promise.then(res=>{
    //         dispatch({type:FETCH_TAXBILL, payload:res.data});
    //     }).catch(err=>{
            
    //         if(err.response.status===500){
    //             alert(err.response.data.message);
    //         }else{
    //             alert('계산서를 가져오는데 실패했습니다.');
    //         }
    //         window.history.back(-1);
    //     })
    // }
}



export function storeTaxbill(storeData){
    const [promise]= ApiClient({
        url:`/api/taxbill/store`,
        data: storeData,
        method:'post',
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export const deleteTaxBill = (id)=>{
    const [promise]= ApiClient({
        url:`/api/taxbill/${id}/delete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
import { useEffect, useReducer, useState } from "react";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import {  useLocation, useNavigate } from "react-router-dom";
import FieldError from "../../component/FieldError";
import { LIBRARY_SERVICE_TYPES } from "../../const";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { fetchSimpleLibraryList } from "app/library/libraryApi";
import { useMutation, useQuery } from "react-query";
import { storeSales } from "./saleApi";

const initialState = {
    id:null,
    libraryId:null,
    service:null,
    aspStdate:null,
    aspEddate:null,
    title:'',
    client:'',
    buyer:'',
    price:0,
    salePrice:0,
    contractDate:null,
    dueDate:null,
    orderDate:null,
    orderPrice:0,
    completeDate:null,
    isComplete:'N'
};

function saleReducer(state, action){
    return {...state, ...action };
}


const SalesForm = ({sales= null})=>{
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useReducer(saleReducer, initialState);
    const [errors, setErrors] = useState([]);
    
    // fetching
    const {data:librarySimpleList, isSuccess } = useQuery({
        queryKey:['librarySimpleList'], 
        queryFn:fetchSimpleLibraryList
    });

    const salesStore = useMutation(storeSales, {
        onSuccess:(res)=>{
            navigate({ pathname: `/sales/view/${res.data}`, search:  location.search });
        },
        onError:(err)=>{
            if( err.response.data ){
                setErrors(err.response.data);
            }else{
                alert('저장에 실패');
            }
        }
    });

    useEffect(() => {
        if(sales===null) return;
        let tempSales = {...sales};
        if(tempSales.aspStdate!==null)tempSales.aspStdate = new Date(tempSales.aspStdate);
        if(tempSales.aspEddate!==null)tempSales.aspEddate = new Date(tempSales.aspEddate);

        if(tempSales.contractDate!==null)tempSales.contractDate = new Date(tempSales.contractDate);
        if(tempSales.dueDate!==null)tempSales.dueDate = new Date(tempSales.dueDate);

        if(tempSales.orderDate!==null)tempSales.orderDate = new Date(tempSales.orderDate);
        if(tempSales.completeDate!==null)tempSales.completeDate = new Date(tempSales.completeDate);
        
        setState( tempSales );
    }, [sales]);

    const handleInput = (event)=>{
        const target = event.target
        if(target.type==='checkbox'){
            setState({ [target.name]: target.checked?target.value:'' });
        }else{
            setState({ [target.name]: target.value });
        }
    }

    const onSave = (event)=>{
        if(salesStore.isLoading) return;
        salesStore.mutate(state);
        event.preventDefault();
    }

    return (
        <div className="salesForm container m-5 ">
            
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">기관</label>
                <div className="col-sm-4">
                <select className="form-select" name="libraryId" onChange={handleInput} defaultValue={state.libraryId}>
                    <option value="">선택</option>
                    { isSuccess && librarySimpleList.data?.map((item)=>{
                            return <option value={item.id} key={item.id}>{item.name}</option>
                        })
                    }
                </select>
                </div>
            
                <label className="col-sm-2 col-form-label">품목(규격)</label>
                <div className="col-sm-4">
                    <select className="form-select" name="service" onChange={handleInput} defaultValue={state.service}>
                        <option value="">선택</option>
                        {
                            Object.keys(LIBRARY_SERVICE_TYPES).map((itemKey)=>{
                                return <option value={itemKey} key={itemKey}>{LIBRARY_SERVICE_TYPES[itemKey]}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">asp 시작일</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko}  selected={state.aspStdate} onChange={(date) => setState({aspStdate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="ASP 시작" />
                </div>
                <label className="col-sm-2 col-form-label">asp 종료</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko} selected={state.aspEddate} onChange={(date) => setState({aspEddate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="ASP 종료" />
                </div>
            </div>
            <div className="mb-3 row ">
                <label className="col-sm-2 col-form-label">계약명</label>
                <div className="col-sm-10">
                    <FieldError name="title" errors={errors} >
                        <input type="text" className="form-control" name="title" value={state.title} onChange={handleInput} />
                    </FieldError>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">발주처</label>
                <div className="col-sm-4">
                    <FieldError name="client" errors={errors} >
                        <input type="text" className="form-control" name="client" value={state.client} onChange={handleInput} />
                    </FieldError>
                </div>
                <label className="col-sm-2 col-form-label">납품처</label>
                <div className="col-sm-4">
                    <input type="text" className="form-control" name="buyer" value={state.buyer} onChange={handleInput} />
                </div>
            </div>

            <hr />

            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">계약일</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko} selected={state.contractDate} onChange={(date) => setState({contractDate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="계얄일자" />
                </div>
                <label className="col-sm-2 col-form-label">납기일</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko} selected={state.dueDate} onChange={(date) => setState({dueDate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="납기일" />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">정가</label>
                <div className="col-sm-4">
                    <CurrencyFormat className="form-control" thousandSeparator={true}  
                        value={state.price} maxLength={12}
                        onValueChange={(values)=>{setState({price:values.value})}} />
                </div>
                <label className="col-sm-2 col-form-label">계약금</label>
                <div className="col-sm-4">
                    <CurrencyFormat className="form-control" thousandSeparator={true}  
                        value={state.salePrice} maxLength={12}
                        onValueChange={(values)=>{setState({salePrice:values.value})}} />
                </div>
            </div>

            <hr />

            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">발주일자</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko} selected={state.orderDate} onChange={(date) => setState({orderDate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="발주일자" />
                </div>
                <label className="col-sm-2 col-form-label">발주금액</label>
                <div className="col-sm-4">
                    <CurrencyFormat className="form-control" thousandSeparator={true}  
                            value={state.orderPrice} maxLength={12}
                            onValueChange={(values)=>{setState({orderPrice:values.value})}} />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">납품일자</label>
                <div className="col-sm-4">
                    <DatePicker locale={ko} selected={state.completeDate} onChange={(date) => setState({completeDate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="납품일자" />
                </div>
            </div>

            <hr />
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">완료</label>
                <div className="col-sm-10">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="isComplete" id="isCompleteYes" value="Y" checked={state.isComplete==='Y'} onChange={handleInput} />
                        <label className="form-check-label" htmlFor="isCompleteYes">완료</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="isComplete" id="isCompleteNo" value="N" checked={state.isComplete==='N'} onChange={handleInput} />
                        <label className="form-check-label" htmlFor="isCompleteNo">미완료</label>
                    </div>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">비고</label>
                <div className="col-sm-10">
                    <textarea name="memo" value={state.memo} onChange={handleInput} className="form-control memo" placeholder="비고"></textarea>
                </div>
            </div>

            <div className="text-center m-lg-5">
                <button className="btn btn-primary" onClick={onSave}>저장</button>
                &nbsp;
                <button className="btn btn-secondary" onClick={()=>navigate(-1)}>목록</button>
            </div>
        </div>
    )
}
export default SalesForm;
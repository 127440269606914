import ApiClient from "utils/api/ApiClient";

export const fetchMemoList = (param)=>{
    const [promise]= ApiClient({
        url:`/api/library/memo/list/${param?.queryKey[1]}`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export function createMemo(libraryId){
    const [promise]= ApiClient({
        url:`/api/library/memo/create`,
        data: {libraryId},
        method:'POST',
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export function updateMemo(storeData){
    const [promise]= ApiClient({
        url:`/api/library/memo/update`,
        data: storeData,
        method:'POST',
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export const deleteMemo = (id)=>{
    const [promise]= ApiClient({
        url:`/api/library/memo/${id}/delete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
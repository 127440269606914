import moment from 'moment';
import jwt_decode from 'jwt-decode';

export const refreshToken = async (config)=>{
    const expireAt = localStorage.getItem('expireAt');
    const accessToken = localStorage.getItem('user');
    const refreshToken = sessionStorage.getItem('refreshToken');
    
    if(accessToken==null || refreshToken==null) return config;
    
    if(moment(expireAt*1000).diff(moment()) <=0 ){
        //axios 전체에 intercepter를 해버려서 그만;;;
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/user/reissue`,
        {
            method:'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + accessToken,
                'Refresh-Token': 'Bearer' + refreshToken
            },
        })

        if(res.status!==200)window.location = '/signin';

        const newAccessToken = await res.text();
        
        const tokenData = jwt_decode(newAccessToken);
        localStorage.setItem('user', newAccessToken);
        localStorage.setItem('expireAt', tokenData.exp);

        // 토큰을 교체
        config.headers["Authorization"] = `Bearer${newAccessToken}`;
    }
    return config;
}

export const refreshTokenError = (error)=>{
    console.log('갱신실패');
    return Promise.reject(error);
};

import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export const Header = ()=>{
    const auth = useSelector(state => state.auth);

    return (
        <header className="navbar navbar-expand-lg navbar-dark bg-dark">
            <nav className="container bd-gutter flex-wrap flex-lg-nowrap">
                <Link className="navbar-brand" to="/">휴맥</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    { auth.authenticated &&
                    <>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                영업현황
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/sales">영업현황</Link></li>
                                <li><Link className="dropdown-item" to="/taxbill">세금계산서</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                이용자
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/admin/user">계정관리</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to="/library">도서관</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to="/reading/video">독서 유튜브</NavLink>
                        </li>
                        </>
                        }
                    </ul>
                    
                    <div className="text-end">
                        { !auth.authenticated && <Link className="btn btn-outline-light" to="/signin">로그인</Link>}
                        { auth.authenticated && <Link className="btn btn-danger" to="/signin">로그아웃</Link>}
                        
                    </div>
                </div>        
            </nav>
        </header>
    );
}
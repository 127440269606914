import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from "react-router-dom";
import { createMemo, fetchMemoList } from "./memoApi";
import { MemoItem } from "./MemoItem";


export const LibraryMemo = ()=>{
    const { libraryId } = useParams();
    const queryClient = useQueryClient();

    // if( undefined===memos ) return null;
    const {data:memos, isLoading, error, isError } = useQuery(['memos', libraryId], fetchMemoList);
    const memoCreate = useMutation(createMemo, {
        onSuccess:()=>queryClient.invalidateQueries(['memos', libraryId]),
    });

    const onCreateMemo = ()=>{
        if(createMemo.isLoading)return;
        memoCreate.mutate(libraryId);
    }

    if(isLoading)return null;
    if(isError)return error;
    

    if(isLoading===null)return null;
    const memoList = memos.data?.map((item)=>{
        return <MemoItem memo={item} key={item.id} />;
    });

    return(
        <div className="libraryMemo mt-4 text-center">
            <button className="btn btn-warning m-3" onClick={onCreateMemo}>메모추가</button>
            <div className="memoList">
                {memoList}
            </div>
        </div>
    );
}
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TaxbillListBySales } from "../taxbill/TaxbillListBySales";
import { completeSales, deleteSales, fetchSales } from "./saleApi";
import { useQuery, useMutation, useQueryClient } from 'react-query';

const SalesView = ()=>{
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    // 가져오기
    const {data:sales, isLoading, error, isError } = useQuery({
        queryKey:['sales', id], 
        queryFn:fetchSales
    });

    // 삭제
    const salesDelete = useMutation(deleteSales, {
        onSuccess:()=>{
            navigate({ pathname: '/sales', search: "?" + location.search });
        },
        onError:(err)=>{
            if(err.response.status===500){
                alert(err.response.data.message);
            }else{
                alert('삭제에 실패했습니다.');
            }
        }
    });

    // 완료처리
    const salesComplete = useMutation(completeSales, { onSuccess:()=> queryClient.invalidateQueries(['sales', id]) });


    // 실제 호출 받는 
    const onDelete = ()=>{
        if(salesDelete.isLoading) return;
        if( window.confirm('삭제하시겠습니까?') ) salesDelete.mutate(id);
    }

    const onComplete = ()=>{
        if(salesComplete.isLoading) return;
        if( window.confirm('완료처리 하시겠습니까?') ) salesComplete.mutate(id);
    }

    
    if( isLoading ) return;
    if( isError ) return error;
    
    return (
    <div className="content_box">
        <div className="sale_view_container row">

            <section className="col-sm-12 col-md-4 col-sm-offset-1 sale_info">
                <h2 className="title">
                    {sales.data?.title}
                </h2>
                <div className="content">
                    <dl className="dl-horizontal">
                        <dt>계약명</dt>
                        <dd> { sales.data?.title } </dd>

                        <dt>품목(규격)</dt>
                        <dd> { sales.data?.service } </dd>

                        <dt>발주처 (납품처)</dt>
                        <dd>
                            { sales.data?.client }
                            { null!==sales.data?.buyer && sales.data?.buyer }
                        </dd>

                        <dt>ASP 기간</dt>
                        <dd> { sales.data?.aspStdate }&nbsp;&nbsp;  { sales.data?.aspEddate } </dd>

                        <dt>계약일</dt>
                        <dd> { sales.data?.contract_date } </dd>

                        <dt>정가 (계약금액)</dt>
                        <dd>
                            { null!==sales.data?.price && sales.data?.price.toLocaleString()}
                            &nbsp; ({ null!==sales.data?.salePrice && (sales.data?.salePrice.toLocaleString()) })
                        </dd>

                        <dt>발주일 (발주금액)</dt>
                        <dd>
                            { sales.data?.orderDate }
                            &nbsp; { null!==sales.data?.orderPrice && ( sales.data?.orderPrice.toLocaleString() ) }
                        </dd>

                        <dt>납기일 (납품일)</dt>
                        <dd>
                            { sales.data?.due_date }
                            { sales.data?.completeDate }
                        </dd>

                        <dt>비고</dt>
                        <dd> {sales.data?.memo}</dd>

                        <dt>완료여부</dt>
                        <dd> {sales.data?.isComplete}&nbsp;</dd>

                        <dt>등록일</dt>
                        <dd>
                            { sales.data?.createdAt }
                        </dd>

                        <dt>수정일</dt>
                        <dd>
                            { sales.data?.updatedAt }
                        </dd>
                    </dl>


                </div>
            </section>

            <section className="col-sm-12 col-md-7 taxbills">
                <h2 className="title">
                    세금계산서 매출
                </h2>
                <div className="content sale_taxbills">
                    <TaxbillListBySales type="SALE" />
                </div>

                <h2 className="title">
                    세금계산서 매입
                </h2>
                <div className="content buy_taxbills">
                    <TaxbillListBySales type="BUY" />
                </div>
            </section>

            <div className="controls text-center">
                <Link className="btn btn-secondary" to={`/sales/edit/${id}${location.search}`}>수정</Link>
                &nbsp;
                <button className="btn btn-danger" onClick={onDelete}>삭제</button>
                &nbsp;
                <Link className="btn btn-secondary" to={`/sales${location.search}`}>목록</Link>
                &nbsp;
                <Link className="btn btn-primary" to={`/sales/taxbill/create/${id}${location.search}`}>계산서 추가</Link>
                &nbsp;
                { sales.data?.isComplete!=='Y' && <button className="btn btn-warning" onClick={onComplete}>완료</button> }
            </div>
        </div>
    </div>
    );
}
export default SalesView;
import { useState } from "react";
import { youtubeDownload, deleteVideoFile, acceptVideo, rejectVideo, deleteReadingYoutube } from './videoApi';
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import useQueryString from "utils/hooks/useQueryString";

const STATE_LIST = {
    'WATTING':'대기',
    'ACTIVE':'활성',
    'REJECT':'거절',
    'DELETE_REQUEST':'삭제요청',
    'DELETE':'삭제'
}; 
    

export const VideoItem = ({video})=>{
    const qsData = useQueryString();
    const [downloadPercentage, setDownloadPercentage] = useState(-1);

    const queryClient = useQueryClient();

    const downloadFile = useMutation(youtubeDownload);
    const deleteFile = useMutation(deleteVideoFile, { onSuccess:()=>queryClient.invalidateQueries(['youtubeList', qsData]) });
    const accept = useMutation(acceptVideo, { onSuccess:()=>queryClient.invalidateQueries(['youtubeList', qsData]) });
    const reject = useMutation(rejectVideo, { onSuccess:()=>queryClient.invalidateQueries(['youtubeList', qsData]) });
    const deleteYoutube = useMutation(deleteReadingYoutube, { onSuccess:()=>queryClient.invalidateQueries(['youtubeList', qsData]) });

    const onFileDownloadProcess = (event)=>{
        if (!event.lengthComputable) return; // guard
        var percentage = Math.floor(event.loaded / event.total * 100);
        setDownloadPercentage(percentage);
    }

    const onFileDownload = ()=>{
        if(downloadFile.isLoading) return;
        let url = video.youtubeInfo.video;
        if( !url.startsWith('http')) url = process.env.REACT_APP_API_HOST+url;
        const name = video.youtubeInfo.title+'_'+video.youtubeInfo.openYn+'.mp4'
        downloadFile.mutate( {url, name, onProgress:onFileDownloadProcess});
    }
   
    const onFileDelete = ()=>{
        if(deleteFile.isLoading)return;
        if(!window.confirm('영상을 삭제하시겠습니까?')) return;
        deleteFile.mutate(video.id);
    }

    const onAccept = ()=>{
        if(accept.isLoading) return;
        const url = window.prompt('주소를 넣어주세요');
        if( null===url )return;
        accept.mutate({id:video.id, url:url});
    }

    const onReject = ()=>{
        if(reject.isLoading)return;
        const message = window.prompt('거절 메시지를 넣어주세요')
        if( null===message )return;
        reject.mutate({id:video.id, message});
    }

    const onDelete = ()=>{
        if(deleteYoutube.isLoading) return;
        if(!window.confirm('독서영상(활동)을 하시겠습니까?')) return;
        deleteYoutube.mutate({id:video.id});
    }

    return (
        <tr className="video">
            <td>{video?.id}</td>
            <td>{video.userInfo.serviceCode}</td>
            <td>{video.userInfo.schoolName}</td>
            <td>{video.userInfo.userid}</td>
            <td>{video.userInfo.userName}</td>
            <td>{video.bookInfo?.title}</td>
            <td>{video.youtubeInfo.title}</td>
            <td>{video.youtubeInfo.openYn==='Y'?'O':'X'}</td>
            <td className={`state ${video.status.state}`}>{ STATE_LIST[video.status.state]}</td>
            <td className="controls">
                { null!==video.youtubeInfo.video && ''!==video.youtubeInfo.video && 
                <>
                    <button className="btn btn-success btn-sm" onClick={onFileDownload}>
                        영상 { downloadPercentage>=0 && downloadPercentage<100 && downloadPercentage+'%'}
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={onFileDelete}>영상삭제</button>
                    <br />
                </>
                }

                {video.status.state==='WATTING' &&
                <>
                    <button className="btn btn-primary btn-sm" onClick={onAccept}>등록</button>
                    <button className="btn btn-warning btn-sm" onClick={onReject}>거절</button>
                </>}

                {video.status.state==='ACTIVE' &&
                <>
                    <a className="btn btn-link"  target="_blank" rel="noreferrer noopener" href={video.youtubeInfo.youtubeUrl} >{video.youtubeInfo.youtubeUrl}</a>
                    <button className="btn btn-primary btn-sm" onClick={onAccept}>수정</button>
                </>
                }

                {/* 삭제는 언제든 가능하다. (가끔 실수했을때를 위해서.. 근데 주의가 필요해) */}
                {video.status.state!=='DELETE' && <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>삭제(주의!!)</button>}

            </td>
            <td>{video.createdAt && moment(video.createdAt).format('LLL')}</td>
        </tr>
    );
}
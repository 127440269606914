import { useRef, useState } from "react";
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from "react-router-dom";
import { deleteMemo, updateMemo } from "./memoApi";

export const MemoItem = ({memo})=>{
    const { libraryId } = useParams();
    const queryClient = useQueryClient();

    const [content, setContent] = useState(memo.content);
    const [isChange, setIsChange] = useState(false);
    const timeRef = useRef(null);

    //set mutate
    const memoDelete = useMutation(deleteMemo, {
        onSuccess:()=>{
            queryClient.invalidateQueries(['memos', libraryId])
            setIsChange(false);
        },
    });

    const memoUpdate = useMutation(updateMemo, {
        onSuccess:()=>{
            queryClient.invalidateQueries(['memos', libraryId]);
            setIsChange(false);
        }
    });

    

    const onSaveMemo = (content)=>{
        if(memoUpdate.isLoading) return;
        memoUpdate.mutate({id:memo.id, content});
    }

    const onChange = (event)=>{
        setContent(event.target.value);
        if(timeRef.current!==undefined) clearTimeout(timeRef.current);
        setIsChange(true);
        timeRef.current = setTimeout(() => {
            onSaveMemo(event.target.value);
        }, 1000);
    }

    const onDelete = ()=>{
        if(memoDelete.isLoading) return;
        if( window.confirm("삭제 하시겠습니까?") ){
            memoDelete.mutate(memo.id);
        }
    }

    return (
        <div className="memo">
            <div className="header">
                {isChange && <button className="btn btn-sm btn-warning" onClick={e=>onSaveMemo(content)}>저장</button>}
                <button className="btn btn-sm btn-danger float-end" onClick={onDelete}>삭제</button>
            </div>
            <textarea onChange={onChange} value={content}></textarea>
        </div>
    );
}
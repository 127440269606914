import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQueryString from "../../utils/hooks/useQueryString";
const queryString = require('query-string');

const LibrarySearch = ()=>{
    const navigate = useNavigate();
    const { keyword } = useQueryString();
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        let tempKeyword='';
        if(undefined!==keyword)tempKeyword = keyword;
        setSearchKeyword(tempKeyword);

        }, [keyword]);

    const fetchList = (page, searchOption={})=>{
        let tempSearchOption = {keyword, page:page, ...searchOption };
        navigate({ pathname: '/library', search: "?" + queryString.stringify(tempSearchOption) });
    }

    const onKeydown = (evt)=>{
        if(evt.key==='Enter'){
            fetchList(0, {keyword:searchKeyword});
        }
    }

    return (
        <div className="row g-3 m-3 justify-content-center">
            
            <div className="col-auto">
                <input className="form-control" placeholder="검색" autoComplete="off" tabIndex={0}
                        value={searchKeyword}
                        onChange={e=>setSearchKeyword(e.target.value)}
                        onKeyDown={onKeydown}
                        aria-label="ddd"
                        name="keyword"
                />
            </div>
            <div className="col-auto">
                <button type="submit" className="btn btn-outline-primary mb-3" onClick={()=>fetchList(0, {keyword:searchKeyword})}>검색</button>
            </div>
        </div>
    );
}
export default LibrarySearch;
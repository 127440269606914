import ApiClient from "utils/api/ApiClient";

export const fetchSimpleLibraryList = ()=>{
    const [promise]= ApiClient({
        url:'/api/library/simple/list',
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export const fetchLibraryList = (params)=>{
    const requestData = {page:0, keyword:null, ...params?.queryKey[1] };
    const [promise]= ApiClient({
        url:'/api/library?page='+requestData.page,
        method:"POST",
        data:requestData,
        authToken:localStorage.getItem('user')
    });
    return promise;
};

export function fetchLibrary(params){
    const [promise]= ApiClient({
        url:`/api/library/${params?.queryKey[1]}`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
import 'core-js/stable'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'moment/locale/ko';
// import {ErrorBoundary} from 'react-error-boundary';
import { store } from 'app/reducers/store';
import { ToastContainer } from 'react-toastify';
import {   QueryClient, QueryClientProvider, } from 'react-query';

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-toastify/dist/ReactToastify.css';
import './scss/index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
// import ErrorPage from './app/ErrorPage';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';

require('utils/api/axiosConfig');
registerLocale('ko', ko)
setDefaultLocale(ko)

 

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    {/* <ErrorBoundary FallbackComponent={ErrorPage} > */}
      <Provider store={store}>   
        <QueryClientProvider client={queryClient}>
        <BrowserRouter >
          <App />
          <ToastContainer stacked position="bottom-center" autoClose={5000} />
        </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    {/* </ErrorBoundary> */}
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';

const PreviousPageButton = ({page, totalPage, gotoPage=f=>f})=>{
    if(page>0){
        return <li className="page-item">
                <button className='page-link' onClick={ ()=>gotoPage(page-1) }>이전</button>
                </li>;
    }
    return <li className="page-item disabled"><button className="page-link">이전</button></li>;
}

const NextPageButton = ({page, totalPage, gotoPage=f=>f})=>{
    if( (page+1)===totalPage || totalPage===0 || totalPage===1 ){
        return <li className="page-item disabled"><button className="page-link">다음</button></li>;
    }
    return <li className="page-item" > <button className="page-link" onClick={ ()=>gotoPage(page+1) }>다음</button></li>;
}

const Pagination = ({page, totalPage, gotoPage=f=>f})=>{

    let startNum = Math.max(page-5, 0);
    let endNum = Math.min(page+5, (totalPage) ) ;
    if(endNum===0)endNum=1;


    return (
        <div className="Page navigation">
            <ul className="pagination justify-content-center">
                {page>0 &&  <li className="page-item"> <button className="page-link" onClick={ ()=>gotoPage(0) }>처음</button> </li> }
                <PreviousPageButton page={page} totalPage={totalPage} gotoPage={gotoPage} />
                {
                    Array.apply(0, {length: (endNum-startNum) }).map(function(value, index){
                        const tmpPage = startNum + index;
                        return (
                            <li className={`page-item ${(tmpPage === page)?'active':''}`} key={tmpPage}>
                                <button className="page-link" onClick={ ()=>gotoPage(tmpPage)}>{tmpPage+1}</button>
                            </li>
                        )
                    })
                }
                <NextPageButton page={page} totalPage={totalPage} gotoPage={gotoPage} />
                { page+1<totalPage &&  <li className="page-item"> <button className="page-link" onClick={ ()=>gotoPage(totalPage-1) }>마지막</button> </li> }
            </ul>
        </div>
    );
}

export default Pagination;


// import React, {Component} from 'react';
// import _ frm 'lodash';



// class Pagination extends Component
// {

//     onClickPage(pageNum)
//     {
//         if( isFunction(this.props.gotoPage)){
//             this.props.gotoPage(pageNum);
//         }
//     }

//     renderFirst(){
//         if(this.props.page>0){
//             return (
//                 <li className="page-item">
//                     <button className="page-link" onClick={ ()=>this.onClickPage(0) }>처음</button>
//                 </li>
//             );
//         }
//     }
//     renderLast(){
//         if( this.props.page+1<this.props.totalPage){
//             return (
//                 <li className="page-item">
//                     <button className="page-link" onClick={ ()=>this.onClickPage(this.props.totalPage-1) }>마지막</button>
//                 </li>
//             );
//         }
        
//     }

//     renderPrevious()
//     {
//         if(this.props.page>0){
//             return <li className="page-item">
//                     <button className="page-link" onClick={ ()=>this.onClickPage(this.props.page-1) }>이전</button>
//                     </li>;
//         }else{
//             return <li className="page-item disabled"><button className="page-link">이전</button></li>;
//         }
//     }

//     renderNext()
//     {
//         if( (this.props.page+1)===this.props.totalPage || this.props.totalPage===0 || this.props.totalPage===1 ){
//             return <li className="page-item disabled"><button className="page-link">다음</button></li>;
//         }else{
//             return <li className="page-item"> <button className="page-link" onClick={ ()=>this.onClickPage(this.props.page+1) }>다음</button></li>;
//         }
//     }

//     renderPages()
//     {
//         const This = this;
//         const page = this.props.page;
//         let startNum = Math.max(this.props.page-5, 0);
//         let endNum = Math.min(this.props.page+5, (this.props.totalPage) ) ;
//         if(endNum===0)endNum=1;

//         return Array.apply(0, {length: (endNum-startNum) }).map(function(value, index){
//             const tmpPage = startNum + index;
//             const className = (tmpPage === page)?"page-item active":"page-item";
//             return (
//                 <li className={className} key={tmpPage}>
//                     <button className="page-link" onClick={ ()=>This.onClickPage(tmpPage)}>{tmpPage+1}</button>
//                 </li>
//             )
//         });
//     }

//     render()
//     {
//         return (
//             <nav aria-label="Page navigation">
//                 <ul className="pagination justify-content-center">
//                     {this.renderFirst()}
//                     {this.renderPrevious()}
//                     {this.renderPages()}
//                     {this.renderNext()}
//                     {this.renderLast()}
//                 </ul>
//             </nav>
//         );

//     }
// }

// export default Pagination;
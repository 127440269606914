
import _ from 'lodash';
import { useMemo } from 'react';

const FieldError = ({children, errors, name})=>{

    const error = useMemo(() => {
        if(errors.length===0)return;
        return _.find(errors, (e)=>e.field===name);
    }, [errors, name]);
    
    return(
        <div className={`fieldValid ${error!==undefined?'invalid':''}`}>
            {children}
            {error!==undefined && <div className="errorMessage">{error.reason}</div>}
        </div>
    )
    // 'reason'
}

export default FieldError;
import ApiClient from "../../../utils/api/ApiClient";
import JsFileDownloader from 'js-file-downloader';

export const fetchYoutubeList = (params)=>{
    // 기본값 만들기
    const requestData = {page:0, state:null, ...params?.queryKey[1] };
    const [promise] = ApiClient({
        url:`/reading/youtube/list?page=${requestData.page}`,
        method:'POST',
        data:requestData,
    });
    return promise;
}

export const youtubeDownload = (download)=>{
    const promise = new JsFileDownloader({
        url:download.url,
        ...(download.name && {filename:download?.name}),
        ...(download.onProgress && {process:download?.onProgress}),
        timeout:600000
    })
    
    promise.then(()=>{
        console.log('called when download ended');
    }).catch((err)=>{
        alert('다운로드 실패 '+err);
    });
    return promise;
};

export const deleteVideoFile = (id)=>{
    const [promise] = ApiClient( {
        url:`/reading/youtube/manage/delete/${id}/video`,
        method:'GET'
    });
    return promise;
}

export function acceptVideo(requestData){
    const [promise] = ApiClient({
        url:`/reading/youtube/manage/accept`,
        method:'POST',
        data:requestData,
    });
    return promise;
}

export function rejectVideo(requestData){
    const [promise] = ApiClient({
        url:`/reading/youtube/manage/reject`,
        method:'POST',
        data:requestData,
    });
    return promise;
}

export function deleteReadingYoutube(requestData)
{
    const [promise] = ApiClient({
        url:`/reading/youtube/manage/delete`,
        method:'POST',
        data:requestData,
    });
    return promise;
}
import { useParams } from "react-router-dom";
import { LibraryHistoryList } from "./history/LibraryHistoryList";
import { LibraryMemo } from "./memo/LibraryMemo";
import LibrarySearch from "./LibrarySearch";
import { useQuery } from 'react-query';
import { fetchLibrary } from "./libraryApi";
import { LibrarySites } from "./site/LibrarySites";

const LibraryView = ()=>{
    const { libraryId } = useParams();
    
    const {data:library, isLoading, error, isError } = useQuery(['libraries',libraryId], fetchLibrary);

    if(isLoading)return null;
    if(isError) return error;

    return (
        <div>
            <LibrarySearch />
            <h2 className="header text-center m-4">{library.data?.name}</h2>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <LibrarySites />
                </div>
                <div className="col-12 col-sm-6">
                    <LibraryHistoryList />
                </div>
            </div>
            <LibraryMemo />
        </div>
    );
}

export default LibraryView;
import ApiClient from "utils/api/ApiClient";

export const fetchLibraryHistoryList = (params)=>{
    const [promise]= ApiClient({
        url:'/api/library/history?page='+params?.queryKey[2]||0,
        data:{libraryId:params?.queryKey[1] },
        method:'POST',
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export const storeHistory = (storeData)=>{
    const [promise]= ApiClient({
        url:'/api/library/history/store',
        data:storeData,
        method:'POST',
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export const deleteHistory = (id)=>{
    const [promise]= ApiClient({
        url:`/api/library/history/${id}/delete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
import ApiClient from "utils/api/ApiClient";

export const fetchSalesList = (params)=>{
    const requestData = {page:0, keyword:null, stdate:null, eddate:null, ...params?.queryKey[1] };
    
    const [promise]= ApiClient({
        url:`/api/sales?page=${requestData.page}`,
        data:requestData,
        method:'POST',
        authToken:localStorage.getItem('user')
    });
    return promise;
}


export const fetchSales = (params)=>{
    const [promise]= ApiClient({
        url:`/api/sales/${params?.queryKey[1]}`,
        authToken:localStorage.getItem('user')
    });

    return promise;
}

export function storeSales(storeData){
    const [promise]= ApiClient({
        url:`/api/sales/store`,
        data: storeData,
        method:'post',
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export const deleteSales = (params)=>{
    const [promise]= ApiClient({
        url:`/api/sales/${params}/delete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}

export const completeSales = (params)=>{
    const [promise]= ApiClient({
        url:`/api/sales/${params}/complete`,
        authToken:localStorage.getItem('user')
    });
    return promise;
}
import { useCallback, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteTaxBill, fetchTaxbillBySalesList } from "./taxApi";

export const TaxbillListBySales = ({type='SALE'})=>{
    const { id } = useParams();
    const location = useLocation();
    const queryClient = useQueryClient();

    const {data:taxbillListBySales, isLoading, isSuccess, error, isError } = useQuery({
        queryKey:['fetchTaxbillBySalesList', id, type], 
        queryFn:fetchTaxbillBySalesList
    });

    const taxBillDelete = useMutation(deleteTaxBill, {
        onSuccess:()=>queryClient.invalidateQueries(['fetchTaxbillBySalesList', id, type]),
        onError:(err)=>{
            if(err.response.status===500){
                alert(err.response.data.message);
            }else{
                alert('삭제에 실패했습니다.');
            }
        }
    });
    

    const onDelete = useCallback((id) => {
        if(taxBillDelete.isLoading) return;
        if( window.confirm('삭제하시겠습니까?') ){
            taxBillDelete.mutate(id);
        }
    }, [taxBillDelete]);
        
    const taxBillElements = useMemo(() =>{
        if(isLoading)return;
        if(isError) return error;
        if(!isSuccess)return null;
        
        // let price = 0;
        // let taxPrice = 0;
        // let sumPrice = 0;
        // let unpaid = 0;
        
        return taxbillListBySales.data?.map((item)=>{
            if(item.type!==type)return null;
            // price += item.price;
            // taxPrice += item.taxPrice;
            // sumPrice += item.sumPrice;
            // if(item.paydate===null || item.paydate==='')unpaid++;

            return(
                <tr className={`taxbill ${(item.paydate===null || item.paydate==='')?'warning':''} `} key={item.id}>
                    <td>{ item.date }</td>
                    <td>{ item.companyName }</td>
                    <td>{ item.productName }</td>
                    <td>{ item.productType }</td>
                    <td>{ item.productCount }</td>
                    <td>{ item.price.toLocaleString() || '' }</td>
                    <td>{ item.taxPrice.toLocaleString() || '' }</td>
                    <td>{ item.sumPrice.toLocaleString() || '' }</td>
                    <td>{ item.paydate }</td>
                    <td>
                        <Link className="btn_modify btn btn-sm btn-primary" to={`/sales/taxbill/edit/${id}/${item.id}${location.search}`}>수정</Link>
                        &nbsp;
                        <button className="btn_destroy btn btn-sm btn-danger" onClick={()=>onDelete(item.id)}>삭제</button>
                    </td>
                </tr>
            )
        });
    }, [error, id, isError, isLoading, isSuccess, location.search, onDelete, taxbillListBySales, type]);
    

    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover taxbill_list">
                <thead>
                    <tr>
                        <th>발행일</th>
                        <th>업체명</th>
                        <th>품목명</th>
                        <th>규격</th>
                        <th>수량</th>
                        <th>금액</th>
                        <th>세액</th>
                        <th>합계금액</th>
                        <th>결제일</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {taxBillElements}
                </tbody>
            </table>
        </div>
    )
}
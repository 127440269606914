import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useQueryString from "../../../utils/hooks/useQueryString";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { TAXBILL_TYPES } from "../../const";
import Pagination from "../../component/Pagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteTaxBill, fetchTaxbillList } from "./taxApi";
import moment from "moment";
const queryString = require('query-string');

export const TaxbillList = ()=>{
    const location = useLocation();
    const navigate = useNavigate();
    const qsData = useQueryString();
    const queryClient = useQueryClient();

        // const [searchService, setSearchService] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchStdate, setSearchStdate] = useState('');
    const [searchEddate, setSearchEddate] = useState('')


    // fetching
    const {data:taxbillList, isSuccess, isLoading, error, isError } = useQuery({
        queryKey:['taxbillList', qsData], 
        queryFn:fetchTaxbillList
    });

    // 삭제
    const taxbillDelete = useMutation(deleteTaxBill, {
        onSuccess:()=>queryClient.invalidateQueries(['taxbillList', qsData]),
        onError:(err)=>{
            if(err.response.status===500){
                alert(err.response.data.message);
            }else{
                alert('삭제에 실패했습니다.');
            }
        }
    });



    useEffect(() => {
        setSearchStdate( ( qsData.stdate && new Date(qsData.stdate) ) || null );
        setSearchEddate( ( qsData.eddate && new Date(qsData.eddate) ) || null );
        setSearchKeyword(qsData.keyword || '');
    }, [qsData.eddate, qsData.keyword, qsData.stdate]);

    const onDelete = useCallback((id) => {
        if( taxbillDelete.isLoading ) return;
        if( window.confirm('삭제하시겠습니까?') ){
            taxbillDelete.mutate(id);
        }
    }, [taxbillDelete]);

    function fetchList(page, option={}){
        searchEddate&&moment(searchEddate).format('YYYY-MM-DD');
        let tempSearchOption = {page, 
            keyword:searchKeyword, 
            stdate:searchEddate && moment(searchStdate).format('YYYY-MM-DD'),
            eddate:searchEddate && moment(searchEddate).format('YYYY-MM-DD'),
            ...option};
        
        navigate({ pathname: '/taxbill', search: "?" + queryString.stringify(tempSearchOption) });
    }

    
    const taxBillElements = useMemo(() =>{
        if(isLoading)return;
        if(isError) return error;
        let buyPrice = 0;
        let buyTaxPrice = 0;
        let buySumPrice = 0;
        let buyUnpaid = 0;

        let salePrice = 0;
        let saleTaxPrice = 0;
        let saleSumPrice = 0;
        let saleUnpaid = 0;
        
        let elements = taxbillList.data?.list.map((item)=>{
            if(item.type==='BUY'){
                buyPrice += item.price;
                buyTaxPrice += item.taxPrice;
                buySumPrice += item.sumPrice;
                if( null===item.paydate ){
                    buyUnpaid++;
                }
            }else{
                salePrice += item.price;
                saleTaxPrice += item.taxPrice;
                saleSumPrice += item.sumPrice;
                if( null===item.paydate ){
                    saleUnpaid++;
                }
            }

            return(
                <tr className={`taxbill ${(item.paydate===null || item.paydate==='')?'table-warning':''} `} key={item.id}>
                    <td>{ TAXBILL_TYPES[item.type] }</td>
                    <td>{ item.date }</td>
                    <td>{ item.companyName }</td>
                    <td>{ item.productName }</td>
                    <td>{ item.productType }</td>
                    <td>{ item.productCount }</td>
                    <td>{ item.price.toLocaleString() || '' }</td>
                    <td>{ item.taxPrice.toLocaleString() || '' }</td>
                    <td>{ item.sumPrice.toLocaleString() || '' }</td>
                    <td>{ item.paydate }</td>
                    <td>
                        <Link className="btn_modify btn btn-sm btn-primary" to={`/taxbill/edit/${item.id}${location.search}`}>수정</Link>
                        &nbsp;
                        <button className="btn_destroy btn btn-sm btn-danger" onClick={()=>onDelete(item.id)}>삭제</button>
                    </td>
                </tr>
            )
        });// end map

        elements.push(
             <tr className="taxbill BUY_taxbill" key="buy_total">
                    <td colSpan="6" className="text-right">매입합계</td>
                    <td>{ buyPrice.toLocaleString() || ''}</td>
                    <td>{ buyTaxPrice.toLocaleString() || ''}</td>
                    <td>{ buySumPrice.toLocaleString() || ''}</td>
                    <td colSpan="3" className="text-left">미결제: <strong>{buyUnpaid}</strong> 건</td>
                </tr>
        );
        elements.push(
                <tr className="taxbill SALE_taxbill" key="sale_total">
                    <td colSpan="6" className="text-right">매출합계</td>
                    <td>{ salePrice.toLocaleString() || ''}</td>
                    <td>{ saleTaxPrice.toLocaleString() || ''}</td>
                    <td>{ saleSumPrice.toLocaleString() || ''}</td>
                    <td colSpan="3" className="text-left">미결제: <strong>{saleUnpaid}</strong> 건</td>
                </tr>
        );
        return elements;
    }, [error, isError, isLoading, location.search, onDelete, taxbillList]);


    return (
        <div className="taxbill_list m-2">
            <div className="controls clearfix">
                <div className="row ">
                    <div className="col-auto">
                        <DatePicker locale={ko} selected={searchStdate} onChange={(date) => setSearchStdate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="시작" />
                    </div>
                    <div className="col-auto">
                        <DatePicker locale={ko} selected={searchEddate} onChange={(date) => setSearchEddate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="종료" />
                    </div>
                    <div className="col-auto" >
                        <input type="text" name="searchKeyword" className="form-control" placeholder="검색" value={searchKeyword} onChange={e=>setSearchKeyword(e.target.value)} />
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-outline-primary mb-3" onClick={()=>fetchList(0)}>검색</button>
                    </div>
                </div>
                <Link className="btn btn-primary float-end" to="/taxbill/create">계약서추가</Link>
            </div>
            
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover taxbill_list">
                    <thead>
                        <tr>
                            <th>종류</th>
                            <th>발행일</th>
                            <th>업체명</th>
                            <th>품목명</th>
                            <th>규격</th>
                            <th>수량</th>

                            <th>금액</th>
                            <th>세액</th>
                            <th>합계금액</th>

                            <th>결제일</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {taxBillElements}
                    </tbody>
                </table>
            </div>
            { isSuccess && <Pagination page={taxbillList.data?.page} totalPage={taxbillList.data?.totalPages} gotoPage={fetchList} /> }
        </div>
    )
}
import { TaxBillForm } from "./TaxbillForm";
import { useParams } from "react-router-dom";

export const TaxBillCreate = ()=>{
    const { salesId } = useParams();

    return (
        <div>
            <TaxBillForm salesId={salesId} />
        </div>
    );
}
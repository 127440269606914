import { SiteItem } from "./SiteItem";
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { fetchSiteList } from "./siteApi";

export const LibrarySites = ()=>{
    // const dispatch = useDispatch();
    const { libraryId } = useParams();
    const {data:sites, isSuccess, isLoading, error, isError } = useQuery(['sites', libraryId], fetchSiteList);
    
    if(isLoading)return null;
    if(isError)return error;

    return (
        <div className="librarySite">
            <div className="siteList">
                {
                    isSuccess &&  sites.data?.map((item, key)=>{
                        return <SiteItem site={item} key={key} />;
                    })
                }
                <SiteItem isCreateForm={true} />
            </div>
        </div>
    )
};
import { useParams } from "react-router-dom";
import SalesForm from "./SalesForm";
import { fetchSales } from "./saleApi";
import { useQuery } from "react-query";

export const SalesEdit = ()=>{
    const { id } = useParams();
    // 가져오기
    const {data:sales, isLoading, error, isError } = useQuery({
        queryKey:['sales', id], 
        queryFn:fetchSales
    });

    if( isLoading ) return;
    if( isError ) return error;

    return (
        <div>
            <SalesForm sales={sales.data} />
        </div>
    )
}
import Axios from 'axios';
import Api from './Api';

const ApiClient = ({url, timeout=10, method="GET", data=null, fileUpload=false, responseType=null})=>{
    let cancel;
    const authToken = localStorage.getItem('user');
    const res = Api({
        method:method,
        baseURL: `${process.env.REACT_APP_API_HOST}${url}`,
        ...(data!==null && !fileUpload && { data: JSON.stringify(data) }),
        ...(data!==null && fileUpload && { data: data }),
        headers: {
            ...( fileUpload && method==="POST" &&
                {
                    "X-Requested-With": "XMLHttpRequest",
                    'Content-Type':"multipart/form-data",
                    ...(responseType && { responseType})
                }
            ),
            ...( !fileUpload && {'Content-Type': 'application/json' }),
            ...( authToken!==null && authToken!==undefined && { Authorization: 'Bearer' + authToken } ),
        },
        cancelToken:new Axios.CancelToken(function executor(c){
            cancel = c;
        }),
        timeout: timeout * 1000,
    });
    return [res, cancel];
}
export default ApiClient;



export const createFetchErrorMessage = (err, defaultMessage = '실패했습니다')=>{
    const errorData = {
        message:defaultMessage,
        errors:[]
    };
    
    if( err.response){
        if(err.response.status===400){
            if(err.response.data.errors.length>0){
                errorData.errors = err.response.data.errors[0].reason;
            }else{
                errorData.message = err.response.data.message;
            }
        }else{
            errorData.message = err.response.data.message;
        }
    }else{
        errorData.message = err.message;
    };
    return errorData;
}
import { useState } from "react";
import { useParams } from "react-router-dom";
import { LIBRARY_SERVICE_TYPES } from "../../const";
import { deleteSite, storeSite } from "./siteApi";
import { useMutation, useQueryClient } from "react-query";


export const SiteItem = ({site=null, isCreateForm=false})=>{
    const { libraryId } = useParams();
    const queryClient = useQueryClient();
    
    const [formToggle, setFormToggle] = useState(false);
    const [service, setService] = useState('');
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');

    const siteDelete = useMutation(deleteSite, {
        onSuccess:()=>queryClient.invalidateQueries(['sites', libraryId]),
        onSettled:()=>setFormToggle(false),
    });
    const siteStore = useMutation(storeSite, {
        onSuccess:()=>queryClient.invalidateQueries(['sites', libraryId]),
        onSettled:()=>setFormToggle(false),
    });
    

    const onDelete = ()=>{
        if(siteDelete.isLoading)return;
        if( window.confirm("삭제하시겠습니까?") )
            siteDelete.mutate(site.id);
    }


    function onStore(){
        if(siteStore.isLoading)return;
        const storeData = {
            id:(site===null)?null:site.id,
            libraryId,
            service, name, url
        }
        siteStore.mutate(storeData);
    }

    const onFormToggle = ()=>{
        setFormToggle(!formToggle);
        
        if(!formToggle){
            if(site!==null){
                setService(site.type);
                setName(site.name);
                setUrl(site.url);
            }else if(isCreateForm){
                setService('');
                setName('');
                setUrl('');
            }
        }
    }

    
    if(formToggle){
        return (
            <div className="siteForm">
                <div className="row m-1">
                    <label className="col-sm-2 col-form-label">서비스</label>
                    <div className="col-sm-10">
                        <select className="form-control" value={service} onChange={e=>setService(e.target.value)}>
                            <option value="">선택</option>
                            {
                                Object.keys(LIBRARY_SERVICE_TYPES).map((itemKey)=>{
                                    return <option value={itemKey} key={itemKey}>{LIBRARY_SERVICE_TYPES[itemKey]}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row m-1">
                    <label className="col-sm-2 col-form-label">이름</label>
                    <div className="col-sm-10">
                        <input className="form-control" value={name} onChange={e=>setName(e.target.value)}  />
                    </div>
                </div>
                <div className="row m-1">
                    <label className="col-sm-2 col-form-label">주소</label>
                    <div className="col-sm-10">
                        <input className="form-control" value={url} onChange={e=>setUrl(e.target.value)}  />
                    </div>
                </div>
                
                <div className="controls">
                    <button className="btn btn-sm btn-primary" type="button" onClick={onStore}>저장</button>
                    &nbsp;
                    <button className="btn btn-sm btn-secondary" type="button" onClick={onFormToggle}>취소</button>
                </div>
            </div>
        )
    }

    if(isCreateForm){
        return (
            <div>
                <button className="btn btn-sm btn-primary" onClick={onFormToggle}>사이트 추가</button>
            </div>
        )
    }
    if(site===null)return;
    
    return (
        <div className={`site ${site.type}`}>
            <span className="name badge bg-secondary">{site.name}</span>
            <a className="url btn btn-link" target="_blank" rel="noreferrer noopener" href={site.url}>{site.url}</a>
            <button className="btn btn-link text-secondary control" onClick={onFormToggle}>수정</button>
            <button className="btn btn-link text-danger control" onClick={onDelete}>삭제</button>
        </div>
    );
}
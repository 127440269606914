import Axios from 'axios';
import { refreshToken, refreshTokenError } from './axiosConfig';
import { toast } from "react-toastify";

const Api = Axios.create({
    baseURL:process.env.REACT_APP_API_HOST,
    timeout:20*1000,
    withCredentials:true
});


Api.interceptors.request.use(refreshToken, refreshTokenError);
//이거 다음에 hooks로 변경하면 좀더 좋을꺼 같은데
Api.interceptors.response.use(function (response) {
    if (response === undefined) return;
    return response;
}, function (error) {
    if (error.response) {
        if (401 === error.response.status) {
            localStorage.removeItem('user');
            localStorage.setItem('authErrorMessage', "로그인 정보가 없습니다.");
            window.location = `/signin`;
        } else if (403 === error.response.status) {
            //이게 의미 있나?   
            window.location = `/signin`;
        } else if (404 === error.response.status) {
            // window.location = `/error404`;
            alert('요청하신 경로는 없거나 삭제되었습니다.');
        } else {
            return Promise.reject(error);
        }
    }
});
export default Api;




export const createFetchErrorMessage = (err, defaultMessage = '실패했습니다') => {
    const errorData = {
        message: defaultMessage,
        errors: []
    };
    
    if (err.response) {
        if (err.response.status === 400) {
            if (err.response.data.errors.length > 0) {
                errorData.errors = err.response.data.errors;
                errorData.message = err.response.data.errors[0].reason;
            } else {
                errorData.message = err.response.data.message;
            }
        } else {
            errorData.message = err.response.data.message;
        }
    } else {
        errorData.message = err.message;
        errorData.message = err.defaultMessage;
    };
    return errorData;
}
export const errorToast = (err, defaultMessage = '실패했습니다') => {
    toast.error( createFetchErrorMessage(err, defaultMessage).message );
};
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { signin } from './authApi';
import { createFetchErrorMessage } from 'utils/api/ApiClient';
import { useDispatch } from "react-redux";
import { authenticated, unauthenticated } from 'app/reducers/auth';

const Signin = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');

    const auth = useMutation(signin, {
        onSuccess:(res)=>{
            dispatch(authenticated(res.data));
            navigate('/');
        },
    });

    // 이 페이지 오면 로그인 정보를 해제 한다
    //  근데 굳이 필요 할까?
    useEffect(() => {
        dispatch(unauthenticated());
    }, [dispatch]);
    

    // 로그인
    const onSubmit = (event)=>{
        event.preventDefault();
        if(auth.isLoading)return;
        const values = { userid:userid, password:password};
        auth.mutate(values);
    }

    return (
        <main className="form-signin w-100 m-auto mt-5">
            <form>
                <h1 className="h3 mb-3 fw-normal">로그인 후 이용해주세요</h1>
                <div className="form-floating">
                    <input type="text" name="userid" placeholder="아이디를 입력하세요" id="floatingInput" className="form-control"
                                                        onChange={(e)=>{setUserid(e.target.value)}} onKeyDown={(event)=>{if(event.key==='Enter')onSubmit(event);}} autoComplete="false" required />
                    <label htmlFor="floatingInput">아이디</label>
                </div>
                <div className="form-floating">
                    <input  type="password" name="password" id="floatingPassword" placeholder="비밀번호를 입력하세요" className="form-control" 
                                                        onChange={(e)=>{setPassword(e.target.value)}} onKeyDown={(event)=>{if(event.key==='Enter')onSubmit(event);}} required />
                    <label htmlFor="floatingPassword">비밀번호</label>
                </div>

                { auth.isLoading && '...인증중' }
                <button type="submit" className="w-100 btn btn-lg btn-primary" onClick={onSubmit} ><strong>로그인</strong></button> 
                {auth.error && <p className="mt-3 mb-3 text-danger text-italic">{createFetchErrorMessage(auth.error)?.message}</p> }
            </form>
        </main>
    );
}// Signin
export default Signin;
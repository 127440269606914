import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../../component/Pagination";
import LibraryHistoryForm from "./LibraryHistoryViewAndForm";
import { useQuery } from 'react-query';
import { fetchLibraryHistoryList } from "./historyApi";

export const LibraryHistoryList = ()=>{
    const { libraryId } = useParams();
    const [page, setPage] = useState(0);

    const {data:historyList, isSuccess, isLoading, error, isError } = useQuery({
        queryKey:['libraryHistoryList', libraryId, page], 
        queryFn:fetchLibraryHistoryList
    });


    const libraryListElements = useMemo(() => {
        if(isLoading)return null;
        if( isError ) return error;
        return historyList.data?.list.map((history)=>{
            return <LibraryHistoryForm isCreateForm={false} history={history} key={history.id} page={page} />;
        });
    }, [error, historyList, isError, isLoading, page]);

    return (
        <div className="libraryHistory">
            <LibraryHistoryForm isCreateForm={true} />
            <div className="historyList">
                {libraryListElements}
            </div>
            {isSuccess && <Pagination page={historyList.data?.page} totalPage={historyList.data?.totalPages} gotoPage={setPage} /> }
        </div>
    );

}